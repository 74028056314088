$.lui("comp", ".comp_calc_pension", function (selector) {
    selector = selector[0]

    const target = {
        inputInvestmentReturnTarget: selector.querySelector('[data-calc-target="inputInvestmentReturn"]'),
        inputPersonDepositTarget: selector.querySelector('[data-calc-target="inputPersonDeposit"]'),
        inputEmployerDepositTarget: selector.querySelector('[data-calc-target="inputEmployerDeposit"]'),
        inputBirthYearTarget: selector.querySelector('[data-calc-target="inputBirthYear"]'),
        outputDepositTotalTarget: selector.querySelector('[data-calc-target="outputDepositTotal"]'),
        outputValorizationTarget: selector.querySelector('[data-calc-target="outputValorization"]'),
        outputTotalTarget: selector.querySelector('[data-calc-target="outputTotal"]'),
        outputYearTarget: selector.querySelector('[data-calc-target="outputYear"]'),
        graphTarget: selector.querySelector('[data-calc-target="graph"]'),
        defaultValuesTarget: selector.querySelector('[data-calc-target="defaultValues"]'),
        offsetSwitchTarget: [...selector.querySelectorAll('[data-calc-target="offsetSwitch"]')],
        taxSavingsTarget: selector.querySelector('[data-calc-target="taxSavings"]'),
        hiddenInputInvestmentReturnTarget: selector.querySelector('[data-calc-target="hiddenInputInvestmentReturn"]'),
        hiddenInputPersonDepositTarget: selector.querySelector('[data-calc-target="hiddenInputPersonDeposit"]'),
        hiddenInputEmployerDepositTarget: selector.querySelector('[data-calc-target="hiddenInputEmployerDeposit"]'),
        hiddenInputPersonDepositMaxTarget: selector.querySelector('[data-calc-target="hiddenInputPersonDepositMax"]'),
        hiddenInputEmployerDepositMaxTarget: selector.querySelector('[data-calc-target="hiddenInputEmployerDepositMax"]'),
        hiddenOutputDepositTotalTarget: selector.querySelector('[data-calc-target="hiddenOutputDepositTotal"]'),
        hiddenOutputValorizationTarget: selector.querySelector('[data-calc-target="hiddenOutputValorization"]'),
        hiddenOutputTotalTarget: selector.querySelector('[data-calc-target="hiddenOutputTotal"]'),
        hiddenCurrencySymbolTarget: selector.querySelector('[data-calc-target="hiddenCurrencySymbol"]'),
        hiddenBirthYearTarget: selector.querySelector('[data-calc-target="hiddenBirthYear"]'),
        hiddenOutputYearTarget: selector.querySelector('[data-calc-target="hiddenOutputYear"]'),
        hiddenTaxSavingsTarget: selector.querySelector('[data-calc-target="hiddenTaxSavings"]'),
        hiddenOffsetTarget: selector.querySelector('[data-calc-target="hiddenOffset"]'),
    }

    const defaultValues = JSON.parse(target.defaultValuesTarget.innerHTML)

    let chart;

    function addDigitSeparator(n) {
        const rx = /(\d+)(\d{3})/;
        return String(n).replace(/^\d+/, function(w) {
            while (rx.test(w)) {
                w = w.replace(rx, '$1 $2');
            }
            return w;
        });
    }

    function calculateAge(dateOfBirth) {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }

    function setValue(input) {
        const inputWrapper = input.parentNode;
        const parentStyles = inputWrapper.style;
        inputWrapper.setAttribute('data-tooltip-text', addDigitSeparator(input.value));
        parentStyles.setProperty('--value', (input.value - input.min) / (input.max - input.min));
    }

    function calculate() {
        let investmentReturn = parseFloat(target.inputInvestmentReturnTarget.textContent.replace(',','.'));
        let personDeposit = parseInt(target.inputPersonDepositTarget.value);
        let employerDeposit = parseInt(target.inputEmployerDepositTarget.value);
        let personDateOfBirth = parseInt(target.inputBirthYearTarget.value).toString();
        let personAge = calculateAge(personDateOfBirth)
        let activeOffset = target.offsetSwitchTarget.filter(function (element) {
            return element.checked
        })[0];
        let offset = parseFloat(activeOffset.value)

        investmentReturn += offset

        let investmentHorizon = Math.max(10, (60 - personAge));
        let startDeposit = 0
        let regularDeposit = personDeposit + employerDeposit

        let taxSavings = Math.min((personDeposit * 12), 48000) * 0.15
        let depositTotal = startDeposit + (regularDeposit * 12 * investmentHorizon);
        let valorization = Math.round(startDeposit * Math.pow(1 + investmentReturn / 100.0, investmentHorizon) + regularDeposit * (Math.pow(1 + (investmentReturn / 1200.0), investmentHorizon * 12) - 1) / (investmentReturn / 1200.0) * (1 + (investmentReturn / 1200.0)));

        valorization = valorization - depositTotal;

        return { depositTotal, valorization, investmentHorizon, taxSavings };
    }

    function setDefaultValues(currency) {
        target.inputPersonDepositTarget.min = defaultValues[currency]['inputPersonDepositMin'];
        target.inputPersonDepositTarget.max = defaultValues[currency]['inputPersonDepositMax'];
        target.inputPersonDepositTarget.step = defaultValues[currency]['inputPersonDepositStep'];
        target.inputPersonDepositTarget.value = defaultValues[currency]['inputPersonDepositValue'];
        target.inputEmployerDepositTarget.min = defaultValues[currency]['inputEmployerDepositMin'];
        target.inputEmployerDepositTarget.max = defaultValues[currency]['inputEmployerDepositMax'];
        target.inputEmployerDepositTarget.step = defaultValues[currency]['inputEmployerDepositStep'];
        target.inputEmployerDepositTarget.value = defaultValues[currency]['inputEmployerDepositValue'];
        target.inputInvestmentReturnTarget.textContent = defaultValues[currency]['inputInvestmentReturn'].toString().replace('.',',') + ' %'
        target.inputPersonDepositTarget.parentElement.dataset.legendText = addDigitSeparator(defaultValues[currency]['inputPersonDepositMax']);
        target.inputEmployerDepositTarget.parentElement.dataset.legendText = addDigitSeparator(defaultValues[currency]['inputEmployerDepositMax']);
    }

    function recalculate(depositTotal, valorization, taxSavings) {
        target.outputDepositTotalTarget.textContent = addDigitSeparator(depositTotal);
        target.outputValorizationTarget.textContent = addDigitSeparator(valorization);
        target.outputTotalTarget.textContent = addDigitSeparator(depositTotal + valorization);
        target.taxSavingsTarget.textContent = addDigitSeparator(taxSavings);

        chart && chart.update({
            series: [depositTotal, valorization]
        })

        setHidden(depositTotal, valorization)
    }

    function setHidden(depositTotal, valorization, taxSavings) {
        let activeOffset = target.offsetSwitchTarget.filter(function (element) {
            return element.checked
        })[0];

        target.hiddenInputInvestmentReturnTarget.value = parseFloat(target.inputInvestmentReturnTarget.textContent.replace(',','.'));
        target.hiddenInputPersonDepositTarget.value = parseInt(target.inputPersonDepositTarget.value);
        target.hiddenInputEmployerDepositTarget.value = parseInt(target.inputEmployerDepositTarget.value);
        target.hiddenInputPersonDepositMaxTarget.value = parseInt(target.inputPersonDepositTarget.max);
        target.hiddenInputEmployerDepositMaxTarget.value = parseInt(target.inputEmployerDepositTarget.max);
        target.hiddenOutputDepositTotalTarget.value = addDigitSeparator(depositTotal);
        target.hiddenOutputValorizationTarget.value = addDigitSeparator(valorization);
        target.hiddenOutputTotalTarget.value = addDigitSeparator(depositTotal + valorization);
        target.hiddenCurrencySymbolTarget.value = 'Kč';
        target.hiddenOffsetTarget.value = activeOffset.nextElementSibling.textContent;
        target.hiddenBirthYearTarget.value = target.inputBirthYearTarget.value;
        target.hiddenOutputYearTarget.value = target.outputYearTarget.textContent;
        target.hiddenTaxSavingsTarget.value = target.taxSavingsTarget.textContent;
    }

    selector.querySelectorAll('input[type="range"]').forEach(element => {
        setValue(element)

        element.addEventListener('input', function(e) {
            const { depositTotal, valorization, taxSavings } = calculate();

            setValue(e.target);
            recalculate(depositTotal, valorization, taxSavings);
        })
    })

    target.inputBirthYearTarget.addEventListener('change', function(e) {
        const currentYear = new Date().getFullYear()

        if(isNaN(parseInt(e.target.value)) || e.target.value.length < 4 || e.target.value > currentYear) {
            return
        }

        const { depositTotal, valorization, investmentHorizon, taxSavings } = calculate();

        recalculate(depositTotal, valorization, taxSavings);

        const finalYear = currentYear + investmentHorizon + 1;
        target.outputYearTarget.textContent = finalYear.toString()
    })

    target.offsetSwitchTarget.forEach(input => {
        input.addEventListener('change', function() {
            const { depositTotal, valorization, taxSavings } = calculate();

            recalculate(depositTotal, valorization, taxSavings);
        })
    })

    let { depositTotal, valorization, taxSavings } = calculate();

    recalculate(depositTotal, valorization, taxSavings);

    $.importStyle(cdnjs.chartist_css);
    $.importScript(cdnjs.chartist, function () {
        chart = new Chartist.Pie(target.graphTarget, {
            series: [depositTotal, valorization]
        }, {
            donut: true,
            showLabel: false
        });
    })
})
